<template>
    <form @submit.prevent="submit" class="reset-pass" @change="validate($event.target.name)">
        <div class="selected">
            تغییر گذرواژه
        </div>

        <p class="mbn-10" dir="ltr">
            {{pathLabel}}
            {{pathMatch === 'EMAIL' ? 'زیر' : pathMatch === 'SMS' ? state.userInfo.mobilenumber : ''}}
            را وارد نمایید
        </p>
        <the-input class="mbn-10" :value="data.email" type="text" :align="data.email? 'left' : 'right'"
                   :invalid-text="state.error.email" name="email" placeholder="ایمیل" readonly/>
        <the-input v-model="data.code" :align="data.code? 'left' : 'right'"
                   :invalid-text="state.error.code" name="code" placeholder="کد ارسال شده"/>
        <p class="mbn-10">
            گذر واژه جدید برای حساب کاربری خود انتخاب کنید
        </p>
        <the-input class="mbn-10" v-model="data.password" type="password" :align="data.password? 'left' : 'right'"
                   :invalid-text="state.error.password" name="password" placeholder="گذرواژه"/>
        <the-input v-model="data.confirmationPass" type="password" :align="data.confirmationPass? 'left' : 'right'"
                   :invalid-text="state.error.confirmationPass" name="confirmationPass" placeholder="تکرار گذرواژه"/>

        <vue-recaptcha class="d-flex-r-ac-g1-s1" sitekey="6LezA0gcAAAAAGAcICq898569tI4DMHOfiYos_oo"
                       :key="theme" :size="theme"
                       theme="light" language="fa"
                       :loadRecaptchaScript="true"
                       @verify="recaptcha=true" @expired="recaptcha=false" @error="recaptcha=false"/>

        <p class="align-start m-font policy">
            <a class="mini-link text-gradient-hover" :href="$href({name : 'Register'})"
               @click.prevent="$push({name : 'Policy'})">قوانین و مقررات</a>
            را مطالعه نموده و میپذیرم.
        </p>
        <the-button flat class="gradient-template-linear-pink" type="submit" :title="Object.values(state.error)">
            ورود
        </the-button>
        <the-button border class="gradient-template-google" type="button">
            ورود / ثبت نام با گوگل
            <inline-svg :src="require('@/assets/Icons/Google-Logo.svg')" style="margin-right: 15px"/>
        </the-button>
    </form>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";
    // import Small from "@/components/Tools/small";

    const pathCode = {
        EMAIL: 'کد ارسال شده به ایمیل',
        SMS: 'کد ارسال شده به شماره همراه',
        GOOGLE_AUTHENTICATOR: 'کد تولید شده در برنامه Google Authenticator',
    }

    const errorTemplate = {
        email: {
            required: true,
            regexName: 'email'
        },
        password: {
            required: true,
            minLength: 8,
            regexName: 'password'
        },
        confirmationPass: {
            required: true,
        },
        code:{
            required: true,
            minLength: 5,
        }
    }

    export default {
        name: "ResetPass",
        components: {TheButton, TheInput, VueRecaptcha},
        props: ['pathMatch'],
        data() {
            return {
                data: {
                    email: this.$cookies.get('username') || this.state.userInfo.email,
                    password: '',
                    confirmationPass: '',
                    code: '',
                },
                recaptcha: false,
                theme: 'normal',
            }
        },
        computed: {
            pathLabel() {
                return pathCode[this.pathMatch]
            }
        },
        methods: {
            async submit() {
                if (await this.$validate(this.data, errorTemplate) && this.recaptcha) {
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    this.$push({name: 'Login'})
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            validate(key) {
                let data = {}
                data[key] = this.data[key]
                this.$validate(data, errorTemplate)
            }
        },
        mounted() {
            this.theme = window.screen.width < 340 ? 'compact' : 'normal'
            this.getData()
            if (this.$route.query.ref || localStorage.referralCode) {
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        },
        beforeDestroy() {
            console.log(document.body.lastChild.remove())
        },
    }
</script>

<style lang="scss" scoped>
</style>